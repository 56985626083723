import React from 'react';
import { CategoryTiles, Typography } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';

import './tiles-two-three.less';
import { getTilesContentArray } from '../../../utility/setHtml';

const TilesTwoThree = ({ highlightedTwoThreeGrid = {}, device }) => {
    const tileLayouts = highlightedTwoThreeGrid?.tileLayouts;
    if (!tileLayouts) {
        return null;
    }
    const { tiles, orientation, variation, sectionHeading } = tileLayouts;
    return (
        <div className="tilesTwoThree">
            <Typography className="heading stellarFix" tag="h2" variant="display">
                {sectionHeading}
            </Typography>
            <CategoryTiles
                tiles={getTilesContentArray(tiles, device === 'mobile')}
                orientation={orientation}
                variation={variation}
            />
        </div>
    );
};

export default withPageComponents(TilesTwoThree, { components: ['highlightedTwoThreeGrid'], device: true });
