import React from 'react';
import loadable from '@loadable/component';

import withPageComponents from '../../../page/components/with-page-components';
import { Helpers } from '../../../core/src/helpers';

const RelatedArticlesContainer = loadable(() => Helpers.retryFunc(() => import('./related-articles-container')));

export default withPageComponents(
    props => {
        const { relatedArticles } = props;
        if (!relatedArticles || relatedArticles.length === 0) {
            return null;
        }
        return <RelatedArticlesContainer title="Related articles" relatedBlogs={relatedArticles} />;
    },
    { components: ['relatedArticles'] },
);
