import React from 'react';

import { Container } from '@hpstellar/core';
import TopSeller from '../../../shared/components/topseller';

import withPageComponents from '../../../page/components/with-page-components';

import withEinstein from '../../../einstein/withEinstein';

import './accessories-top-sellers.less';

function AccessoriesTopSellers({ accessoriesTopSellers, analyticsData, list, vanityUrl, einsteinProducts }) {
    const { title } = accessoriesTopSellers || {};
    const products =
        einsteinProducts && einsteinProducts.length > 0
            ? einsteinProducts
            : accessoriesTopSellers && accessoriesTopSellers.products;
    if (!products || products.length < 1) return null;

    const carouselSettings = {
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        dots: true,
        showDotsCounter: true,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    draggable: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,
                    dots: true,
                    infinite: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    draggable: false,
                    slidesToShow: 2.25,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    infinite: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    draggable: false,
                    slidesToShow: 1.25,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    infinite: false,
                },
            },
        ],
    };

    return (
        <Container className="accessoriesTopSellerContainer">
            <TopSeller
                className="accessoriesTopSellers"
                productTileClassName="accessoryAttachableTile"
                products={products}
                title={title || 'Top Sellers'}
                carouselSettings={carouselSettings}
                list={list || `${(analyticsData && analyticsData.page_level) || 'cat'}-topsellers`}
                componentKey="accessoriesTopSellers"
                vanityUrl={vanityUrl}
                trackImpressions
            />
        </Container>
    );
}

export default withEinstein(
    withPageComponents(AccessoriesTopSellers, { components: ['accessoriesTopSellers'], analyticsData: true }),
    'accessoriesTopSellers'
);
