import React from 'react';

import {ContentCardBanner} from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import { getBannerContentArray } from '../../../utility/setHtml';

import './promo-tiles.less';

export default withPageComponents(
    ({ latestPromos = {} }) => {
        const { contentCardBanners = {} } = latestPromos;
        const { banners } = contentCardBanners;
        if (banners && banners.length > 0) {
            return <ContentCardBanner className="promoTiles" banners={getBannerContentArray(banners)} />;
        }
        return null;
    },
    { components: ['latestPromos'] }
);
