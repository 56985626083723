import React, { useState } from 'react';
import { Typography, Tabs, Link, Container, Button } from '@hpstellar/core';

import withWindowResize from '../../../ui/components/window-resize';
import { getIcon } from '../shared';
import withPageComponents from '../../../page/components/with-page-components';
import ModelCarousel from './model-carousel';
import './model-by-use.less';

const getActiveModels = (models, index) => {
    const modelList = models[index];
    if (modelList) {
        return modelList;
    }
    return {};
};

const getIcons = (list, category) =>
    list.map(({ name, gtmValue }) => ({ tab: { title: name, gtmValue, icon: getIcon(name, category) } }));

const ModelByUse = ({ modelByUse = {}, width, vanityUrl }) => {
    const [activeAreaIndex, setActiveAreaIndex] = useState(0);
    const { modelList, sectionTitle, tabGtmActions, bgColor } = modelByUse;
    const [dir, category] = (typeof vanityUrl === 'string' && vanityUrl.toLowerCase().split('/')) || [];

    if (!modelList || modelList.length === 0) return null;

    let { modelDetails, name, viewMoreText, viewMoreLinkGtmAttributes, ctaLink } = getActiveModels(
        modelList,
        activeAreaIndex,
    );
    viewMoreText = viewMoreText || `View all ${name}`;
    return (
        <Container
            className={`models-section${modelByUse.isCarepack ? ' models-section-carepack' : ''}${
                bgColor ? ' bgColor' : ''
            }`}
        >
            <Typography className="stellarFix" tag="h2" variant="display">
                {sectionTitle}
            </Typography>
            <div className="tabs-container">
                <Tabs
                    id="clpTabs"
                    list={getIcons(modelList, category)}
                    activeIndex={activeAreaIndex}
                    className="tabs"
                    tabsListProps={{
                        type: 'secondary',
                        size: 'large',
                        onClick: setActiveAreaIndex,
                        gtmActions: tabGtmActions,
                        tabTitleTag: 'h4',
                        className: 'tabs-list',
                    }}
                />
            </div>
            <ModelCarousel models={modelDetails} key={activeAreaIndex} titleTag="h3" />
            {width > 768 ? (
                <Link {...(viewMoreLinkGtmAttributes || {})} className="view-models" to={ctaLink}>
                    {viewMoreText}
                </Link>
            ) : (
                <Button
                    {...(viewMoreLinkGtmAttributes || {})}
                    className="view-models-button"
                    to={ctaLink}
                    variation="secondary"
                >
                    {viewMoreText}
                </Button>
            )}
        </Container>
    );
};

export default withPageComponents(withWindowResize(ModelByUse), { components: ['modelByUse'] });
