import React from 'react';

import { Typography, ContentCardBanner } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import { getBannerContentArray } from '../../../utility/setHtml';

import './accessories-content.less';

function AccessoriesContent({ accessoriesContent }) {
    const contentCardBanners = accessoriesContent?.contentCardBanners;
    if (!contentCardBanners) return null;

    const { sectionHeading, banners } = contentCardBanners;

    return (
        <div className="accessoriesContent" centered>
            <Typography className="header stellarFix" tag="h1" variant="display">
                {sectionHeading}
            </Typography>
            <ContentCardBanner banners={getBannerContentArray(banners, { image: { loading: 'eager' } })} />
        </div>
    );
}

export default withPageComponents(AccessoriesContent, { components: ['accessoriesContent'] });
