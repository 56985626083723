import React from 'react';
import { CategoryTiles, Typography } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import { Helpers } from '../../../core/src/helpers';
import { isSVG, isImageSrc, getIcon } from '../shared';

import './shop-by-category.less';

const getTiles = Helpers.memoize(
    (vanityUrl, tiles) => {
        try {
            return tiles.map(t => {
                //shallow copy objects to prevent mutation of redux store
                const tile = { ...t };
                const { image } = tile;
                const newImage = { ...image };
                const { icon } = newImage;
                if (isImageSrc(icon)) {
                    newImage.icon = <img src={icon} style={{ maxWidth: '104px' }} />;
                } else if (isSVG(icon)) {
                    newImage.icon = <span className="svg-icon" dangerouslySetInnerHTML={Helpers.createMarkup(icon)} />;
                } else if (typeof icon === 'string') {
                    const mappedIcon = getIcon(icon);
                    mappedIcon && (newImage.icon = mappedIcon);
                }
                tile.image = newImage;
                return tile;
            });
        } catch (e) {
            return tiles;
        }
    },
    (vanityUrl, tiles) => {
        return `${vanityUrl}-${tiles && tiles.length}`;
    }
);

export const ShopByCategory = ({ vanityUrl, shopByCategory = {} }) => {
    const tileLayouts = shopByCategory?.tileLayouts;

    if (!tileLayouts) {
        return null;
    }

    const {
        tiles,
        sectionHeading,
        defaultExpanded,
        defaultExpandedMobile,
        collapseLabel,
        collapseLabelMobile,
        expandLabel,
        expandLabelMobile,
        variation,
    } = tileLayouts;

    return (
        <div className="shopByCategory">
            <Typography className="heading stellarFix" tag="h2" variant="display">
                {sectionHeading}
            </Typography>
            <CategoryTiles
                tiles={getTiles(vanityUrl, tiles)}
                orientation="vertical"
                variation={variation}
                defaultExpanded={defaultExpanded}
                collapseLabel={collapseLabel}
                expandLabel={expandLabel}
                className={'shop-by-category-tiles'}
            />
        </div>
    );
};

export default withPageComponents(ShopByCategory, { components: ['shopByCategory'] });
