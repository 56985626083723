import React from 'react';
import { Typography, Container } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import ModelCarousel from './model-carousel';
import './model-by-brand.less';

export default withPageComponents(
    ({ modelByBrand = {} }) => {
        const { modelList, sectionTitle, carouselGtmActions } = modelByBrand;
        if (modelList && modelList.length > 0) {
            return (
                <Container className={'models-by-brand'}>
                    <Typography className="stellarFix" tag="h2" variant="display">
                        {sectionTitle}
                    </Typography>
                    <ModelCarousel models={modelList} carouselGtmActions={carouselGtmActions} />
                </Container>
            );
        }
        return null;
    },
    { components: ['modelByBrand'] }
);
