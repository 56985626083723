import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Helpers } from '../../../core/src/helpers';
import { fetchPrices, viewPrice, NO_PRICE } from '../../product-actions';
import { fetchModelPrices } from './model-actions';

const ProductPriceWrapper = Component => props => {
    return <Component {...props} />;
};

const mapPriceToProps = (state, ownProps) => {
    const price =
        ownProps.ownPrice && ownProps.price
            ? ownProps.price
            : Helpers.getProductPrice(ownProps.product, state.productData.productInfo.productPrices);
    //include user profile data since it can relate to price info
    let {
        userData: {
            profileData: { personData },
        },
    } = state;
    return {
        price,
        personData,
    };
};

const mapStateToProps = (state, ownProps) => {
    try {
        return {
            prices: state.productData.productInfo.productPrices,
            modelPrices: state.productData.productInfo.modelPrices,
            user: state.user,
        };
    } catch (e) {}

    return {
        prices: {},
        modelPrices: {},
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchPrices: Helpers.memoize(ids => dispatch(fetchPrices(ids))),
        fetchModelPrices: Helpers.memoize(ids => dispatch(fetchModelPrices(ids))),
        viewPrice: sku => dispatch(viewPrice(sku)),
    };
};

/**
 * Use withProductPrice when you need the price for an individual sku
 * @type {[type]}
 */
export const withProductPrice = compose(connect(mapPriceToProps, mapDispatchToProps), ProductPriceWrapper);

/**
 * Use withPrices when you need prices for several skus at once
 * @type {[type]}
 */
const withPrices = compose(connect(mapStateToProps, mapDispatchToProps), ProductPriceWrapper);

const selectPrices = Helpers.memoize(
    (prices, products, user) => {
        return products.reduce((r, p) => {
            let priceObj = Helpers.getProductPrice(p, prices);
            if (user && user.TierPriceMsg && priceObj.tierPrice) {
                priceObj.tierMsg = user.TierPriceMsg;
            }
            r[p.sku] = priceObj;
            return r;
        }, {});
    },
    (prices, products) => {
        return products.reduce((r, p) => {
            return r.concat(`${p.sku}-${p.sku in prices}`);
        }, ``);
    }
);

const selectModelPrices = Helpers.memoize(
    (prices, productModels, user) => {
        return productModels.reduce((r, p) => {
            let priceObj = prices[p.id];
            if (user && user.TierPriceMsg && priceObj.tierPrice) {
                priceObj.tierMsg = user.TierPriceMsg;
            }
            r[p.id] = priceObj;
            return r;
        }, {});
    },
    (prices, productModels) => {
        return productModels.reduce((r, p) => {
            return r.concat(`${p.id}-${p.id in prices}`);
        }, ``);
    }
);

/**
 * Bulk fetch prices and inventory data for products and product models
 *
 * @param {Object[]} props.products - products to fetch price and inventory data for
 * @param {Object[]} props.productModels - models to fetch model prices for
 * @param {Function} props.fetchPrices - action to fetch product prices
 * @param {Function} props.fetchModelPrices - action to fetch model prices
 * @param {Object} props.price - hash map (by sku id) of existing product price data
 * @param {Object} props.modelPrice - hash map (by model id) of existing model price data
 * @param {Object} props.user - Logged in user data
 */
export const PriceContainer = withPrices(
    ({ products = [], productModels = [], fetchPrices, fetchModelPrices, prices, modelPrices, user, children }) => {
        useEffect(() => {
            let noPrice = products.filter(({ sku, itemId, catentryId, prdClass }) => {
                const priceFailure = prices[itemId || catentryId];
                //when prices fai to fetch, we get no response back so we dont know the sku
                // which mean we need to check for fetch fail by itemId
                return !(prdClass in NO_PRICE) && !(sku in prices) && (!priceFailure || !priceFailure.priceFetchFailed);
            });
            let noModelPrice = productModels.filter(({ id }) => {
                const priceFailure = modelPrices[id];
                //when prices fai to fetch, we get no response back so we dont know the sku
                // which mean we need to check for fetch fail by itemId
                return !(id in modelPrices) && (!priceFailure || !priceFailure.priceFetchFailed);
            });
            if (noPrice.length > 0) {
                fetchPrices(noPrice.map(p => p.itemId || p.catentryId));
            }
            if (noModelPrice.length > 0) {
                fetchModelPrices(noModelPrice.map(p => p.id));
            }
        });
        return children({
            prices: selectPrices(prices, products, user),
            modelPrices: selectModelPrices(modelPrices, productModels, user),
        });
    }
);

export default withPrices;
