import React from 'react';
import { ContentCardBanner, Typography } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import { getBannerContentArray } from '../../../utility/setHtml';

import './ink-toner-exclusives.less';

function InkTonerExclusives({ inkTonerExclusives, title = 'Featured products & offers' }) {
    if (!inkTonerExclusives || inkTonerExclusives.length < 1) return null;

    return (
        <div className="MprExclusiveSavings">
            <Typography variant="display" tag="h1" className="offers-header stellarFix">
                {title}
            </Typography>
            <ContentCardBanner
                orientation="horizontal"
                banners={getBannerContentArray(inkTonerExclusives, {
                    image: { loading: 'eager' },
                    titleTypographyProps: { tag: 'h3' },
                })}
            />
        </div>
    );
}

export default withPageComponents(InkTonerExclusives, { components: ['inkTonerExclusives'] });
