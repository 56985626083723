import React from 'react';

import FooterLogo from '../../../shared/components/footer-logo';
import withPageComponents from '../../../page/components/with-page-components';

const FooterPartnerLogo = ({ categoryTitleSection }) => {
    if (!categoryTitleSection?.logos) return null;
    return <FooterLogo logos={categoryTitleSection?.logos} breakpoint={1024} />;
};

export default withPageComponents(FooterPartnerLogo, { components: ['categoryTitleSection'] });
