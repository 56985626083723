import { getPunchOutProps } from '../product/get-product-props';
import { getTileCtaLink, getImageLink } from '../product/get-product-props/util';

/**
 * Derives commonly use props from product and price information
 * @param {*} model
 * @param {*} price
 * @param {*} hooks - Pass in hook, like useAddToCart and useProductCompare
 */

// TODO: move to getProductProps?
function getModelProps(model, priceObj) {
    const {
        title,
        name,
        ctaLink: productUrl,
        ctaText = 'View Details',
        image,
        text: violatorMessage,
        numberProducts: numberOfProducts,
        ksps: specFirstGlance,
        numberReviews: reviewsCount,
        averageRatings: score,
        gtmActions,
        hpRewards,
    } = model;
    const { regularPrice, salePrice, locale, currency } = priceObj || {};
    const price =
        salePrice !== undefined
            ? { regularPrice, salePrice, locale, currency, customPrefix: 'Starting at' }
            : undefined;
    // TODO: find out of this is correct, looks like image is an array of array in api
    // leaving alone for now
    let images = typeof image === 'object' ? image[0] : image;
    const staticAltText = title || name;
    images =
        typeof images === 'object'
            ? images.map((i, idx) => {
                  const loading = idx === 0 ? 'eager' : 'lazy';
                  if (typeof i === 'string') {
                      return { src: getImageLink(i), alt: staticAltText, loading };
                  }
                  const { image, altText } = i;
                  return {
                      src: `${getImageLink(image)}`,
                      alt: altText || staticAltText,
                      loading,
                  };
              })
            : [{ src: `${getImageLink(images)}`, alt: staticAltText, loading: 'eager' }];

    const rewardBadge =
        typeof hpRewards === 'object'
            ? {
                  message: hpRewards.message,
                  tooltipContent: {
                      title: hpRewards.message,
                      description: hpRewards.tooltip,
                  },
              }
            : null;

    const prdUrl = getTileCtaLink(productUrl);

    return {
        product: {
            title: title || name,
            productUrl: prdUrl,
            violatorMessage,
            images,
            numberOfProducts,
            specFirstGlance,
        },
        rating:
            score || reviewsCount
                ? {
                      score,
                      reviewsCount,
                  }
                : undefined,
        ctaBtnText: ctaText,
        price,
        ctaLink: prdUrl,
        ...getPunchOutProps(priceObj),
        gtmActions,
        rewardBadge,
    };
}

export default getModelProps;
