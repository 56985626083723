import { useSelector } from 'react-redux';

import { Helpers } from '../core/src/helpers';
import useTestFlags from '../abtest/useTestFlags';

const mapProps = Helpers.memoize(
    (themeName, props, dependancy) => {
        if (!props) return {};
        if (dependancy) {
            //if dependacy is provided, return the props that match the dependancy
            return props.reduce((acc, prop) => {
                const { key, props } = prop;
                if (dependancy.includes(key)) {
                    acc[key] = props;
                }
                return acc;
            }, {});
        }
        return {
            props: props.reduce((acc, prop) => {
                const { key, props } = prop;
                acc[key] = props;
                return acc;
            }, {}),
        };
    },
    (themeName, props, dependancy) => `${themeName}${(dependancy || []).join('-')}`,
);

const useTemplateTheme = dependancy => {
    const theme = useSelector(state => state.slugInfo.theme);
    const { templateThemes } = useTestFlags(['templateThemes']);
    const { enabled } = templateThemes || {};
    if (!enabled) return {};
    const { css, props, key: themeName } = theme || {};
    return { ...mapProps(themeName, props, dependancy), css, themeName };
};

export const WithTheme = ({ children, CSSLoader }) => {
    const theme = useTemplateTheme();
    const { css } = theme;
    return (
        <div className={css}>
            {CSSLoader && <CSSLoader css={css} />}
            {children}
        </div>
    );
};

export default useTemplateTheme;
