import React from 'react';
import { Container, SkeletonLoader } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';

const Tiles = ({ highlightedGrid = {} }) => {
    const tileLayouts = highlightedGrid?.tileLayouts;

    if (!tileLayouts) {
        return null;
    }
    const { tiles } = tileLayouts;
    return (
        <div>
            <Container className={`categoryTilesContainer categoryTilesContainer-loader`}>
                {tiles.map(t => (
                    <SkeletonLoader className="content-card-banner" />
                ))}
            </Container>
        </div>
    );
};

export default withPageComponents(Tiles, {
    components: ['highlightedGrid'],
});
