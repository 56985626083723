import React from 'react';
import {Carousel,ModelTile} from '@hpstellar/core';

import getModelProps from '../../../mlp/get-model-props';

const pickModelIds = models => models.map(({ modelId }) => ({ id: modelId }));

const getSlickSettings = () => {
    const settings = {
        dots: true,
        draggable: false,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        className: 'modelByBrandCarousel',
        nextArrowClassName: 'upsellArrowNext',
        prevArrowClassName: 'upsellArrowPrev',
        lazyLoad: 'progressive',
        infinite: true,
        showDotsCounter: true,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    draggable: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,
                    dots: true,
                    infinite: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    draggable: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: true,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    draggable: true,
                    slidesToShow: 1.33,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    infinite: false,
                },
            },
        ],
    };
    return settings;
};

export default ({ models = [], carouselGtmActions }) => {
    if (models && models.length > 0) {
        return (
            <Carousel {...getSlickSettings()} gtmActions={carouselGtmActions} equalHeight>
                {models.map((model, idx) => {
                    const { modelId } = model;
                    return (
                        <ModelTile
                            className="modelTile"
                            key={`${modelId}-${idx}`}
                            {...getModelProps(model)}
                            fullHeight
                        />
                    );
                })}
            </Carousel>
        );
    }
    return null;
};
