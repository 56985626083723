import React from 'react';

import withPage from '../page';
import CompareDrawer from '../compare/components/compare-drawer';
import CategoryLandingPage from './components';

const CLPContainer = ({ slugInfo }) => {
    return (
        <>
            <CategoryLandingPage slugInfo={slugInfo} />
            <CompareDrawer />
        </>
    );
};

export default withPage(CLPContainer, {
    wrapperClassName: 'clp-root',
    showWordCloud: true,
    useV2: true,
    useGraphQL: true,
});
