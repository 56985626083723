import React from 'react';
import {Carousel,ModelTile} from '@hpstellar/core';

import { PriceContainer } from '../../../product/components/product-price/with-product-price';
import getModelProps from '../../../mlp/get-model-props';

const pickModelIds = models => models.map(({ modelId }) => ({ id: modelId }));

const getSlickSettings = () => {
    return {
        dots: true,
        draggable: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        className: 'modelByUseCarousel',
        nextArrowClassName: 'upsellArrowNext',
        prevArrowClassName: 'upsellArrowPrev',
        mockImagesAmount: 2,
        lazyLoad: 'progressive',
        infinite: true,
        showDotsCounter: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    draggable: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: true,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    draggable: true,
                    slidesToShow: 1.33,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    infinite: false,
                },
            },
        ],
    };
};

export default ({ models = [], titleTag = 'h3' }) => {
    if (!models || models.length === 0) return null;

    return (
        <PriceContainer productModels={pickModelIds(models)}>
            {({ modelPrices }) => (
                <Carousel {...getSlickSettings()} equalHeight>
                    {models.map(model => {
                        const { modelId } = model;
                        return (
                            <ModelTile
                                className="model-tile"
                                key={modelId}
                                {...getModelProps(model, modelPrices[modelId])}
                                fullHeight
                                titleTag={titleTag}
                            />
                        );
                    })}
                </Carousel>
            )}
        </PriceContainer>
    );
};
