import React from 'react';

import { Banner, BannerContent } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import usePromotionImpressions from '../../../hooks/usePromotionImpressions';

import { getBannerContent } from '../../../utility/setHtml';

import './secondary-banner.less';

const SecondaryBanner = ({ innovationBanner, analyticsData }) => {
    usePromotionImpressions({
        data: [innovationBanner],
        id: 'innovationBanner',
        analyticsData,
        componentKey: 'innovationBanner',
    });

    if (!innovationBanner) return null;
    const { contentAlignment, image, type, ...bannerContentProps } = innovationBanner;

    // TODO: remove once json tool is updated with ctaGroup
    if (innovationBanner?.cta) {
        innovationBanner.ctaGroup = [innovationBanner?.cta];
    }

    return (
        <div className="secondaryBanner">
            <Banner contentAlignment={contentAlignment} image={image} type={type}>
                <BannerContent {...getBannerContent(bannerContentProps || {})} currentSlide={true} />
            </Banner>
        </div>
    );
};

export default withPageComponents(SecondaryBanner, { components: ['innovationBanner'], analyticsData: true });
