import React from 'react';

import { PartnerLogos, Typography, Container } from '@hpstellar/core';

import { getPartnerLogoContent } from '../../utility/setHtml';
import useTemplateTheme from '../../hooks/useTemplateTheme';

import './title-section.less';

export default function TitleSection({ title, partnerLogoProps, className, typographyVariant, titleTag = 'h1' }) {
    const { css } = useTemplateTheme(['tabs']);
    return (
            <Container className={css? `${css}-title-section-wrapper` : ""}>
                <div className={css? `${className} titleSection ${css}-title`: `${className} titleSection`}>
                    <Typography className="title" tag={titleTag} variant={typographyVariant || 'titleL'}>
                        {title}
                    </Typography>
                    <PartnerLogos logos={getPartnerLogoContent(partnerLogoProps?.logos)} className="logo" />
                </div>
            </Container>
    );
}
